<template>
  <v-dialog
    v-model="show"
    max-width="1000px"
    persistent
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card>
      <v-toolbar
        dark
        color="#7253CF"
        :height="$vuetify.breakpoint.smAndUp ? '128' : '100px'"
      >
        <div class="ml-2 ml-sm-10">
          <v-img
            :width="$vuetify.breakpoint.smAndUp ? '102' : '56'"
            src="@/assets/publicDonationPage/makeDonationModalLogo.svg"
          ></v-img>
        </div>
        <v-spacer></v-spacer>
        <v-toolbar-title class="modalTitle mr-10"
          ><div>Make Donation</div></v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-btn class="align-self-baseline" icon @click="show = false">
          <v-icon size="32">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form ref="donationForm" lazy-validation>
        <v-card-text style="height: 610px; overflow-y: auto">
          <v-row class="pb-5 pt-5">
            <v-col class="py-0 px-10">
              <div class="sectionTitle mb-5" style="width: fit-content">
                <span>Donor Information</span>
                <v-divider
                  class="sectionTitleUnderline mt-1"
                  style=""
                ></v-divider>
              </div>
              <v-row>
                <v-col cols="12" sm="6">
                  <span class="textField-label">First Name</span>
                  <v-text-field
                    v-model="donorFirstName"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  :class="$vuetify.breakpoint.xsOnly ? 'pt-0' : ''"
                >
                  <span class="textField-label">Last Name</span>
                  <v-text-field
                    v-model="donorLastName"
                    outlined
                    dense
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="mt-n7">
                  <span class="textField-label">Email</span>
                  <v-text-field
                    v-model="donorEmail"
                    outlined
                    dense
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" class="mt-n7">
                  <span class="textField-label">Phone</span>
                  <v-text-field
                    v-model="donorPhone"
                    outlined
                    dense
                    :rules="[rules.required]"
                    @keypress="isNumber($event)"
                  ></v-text-field>
                </v-col>
                <span
                  style="
                    font-family: auto;
                    font-size: 18px;
                    margin-bottom: 25px;
                    margin-left: 15px;
                    margin-top: -21px;
                  "
                  >Thank you for entering your information. This will allow us
                  to remove you from recurring donation request.</span
                >
                <br />
                <br />
                <v-col cols="12" class="mt-n7">
                  <span class="textField-label">Special message from you</span>
                  <v-textarea
                    v-model="donorSpecialMsg"
                    outlined
                    rows="4"
                    hide-details
                    placeholder="Write special message"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col
              :class="$vuetify.breakpoint.smAndUp ? 'py-0 px-10' : 'px-10'"
            >
              <div class="sectionTitle mb-5" style="width: fit-content">
                <span class="textField-label">Payment Information</span>
                <v-divider
                  class="sectionTitleUnderline mt-1"
                  style=""
                ></v-divider>
              </div>
              <v-row>
                <v-col
                  v-if="false"
                  cols="12"
                  style="position: relative; width: 100%"
                >
                  <v-text-field
                    placeholder="Select student/teacher/school/district"
                    prepend-inner-icon="mdi-magnify"
                    outlined
                    dense
                    hide-details
                    v-model="searchQuery"
                    :loading="searchLoading"
                  ></v-text-field>
                  <v-expand-transition>
                    <v-card
                      v-show="searchQuery"
                      light
                      style="position: absolute; z-index: 5; overflow-y: auto"
                      class="card-searchResult mt-3"
                      min-width="90%"
                      max-height="250px"
                      elevation="20"
                    >
                      <v-list
                        height="100%"
                        style="overflow-y: auto"
                        class="pa-0"
                        three-line
                      >
                        <template>
                          <v-list-item
                            @click="searchItemClickHandler(item)"
                            v-for="(item, index) in searchList"
                            :key="index"
                          >
                            <v-list-item-avatar class="align-self-center">
                              <v-img
                                v-if="item.profile_pic !== null"
                                :src="item.profile_pic"
                              ></v-img>
                              <v-avatar v-else color="red" size="40">
                                <span class="white--text text-h6">{{
                                  getInitials(item.name)
                                }}</span>
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>
                                <span class="searchList-title">{{
                                  item.name
                                }}</span>
                                <v-chip
                                  small
                                  class="mx-2 mt-n1"
                                  :class="item.user_type"
                                  style="font-size: 17px"
                                >
                                  {{ item.user_type }}
                                </v-chip>
                              </v-list-item-title>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'student'"
                                v-html="
                                  `${item.grade_teacher} (Grade ${item.grade_title})`
                                "
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'student'"
                                v-html="item.school_name"
                              ></v-list-item-subtitle>
                              <v-list-item-subtitle
                                class="searchList-secondryText"
                                v-if="item.user_type === 'teacher'"
                                v-html="
                                  `Grade ${item.grade_title} (${item.school_name})`
                                "
                              ></v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider
                            v-if="index < searchList.length - 1"
                            :key="index + 'div'"
                          ></v-divider>
                        </template>
                      </v-list>
                    </v-card>
                  </v-expand-transition>
                </v-col>
                <v-col cols="12">
                  <span class="textField-label">You're donating to</span>
                  <v-divider></v-divider>
                  <v-list-item v-show="selectedSearch.name" style="z-index: 1">
                    <v-list-item-avatar class="align-self-center">
                      <v-img
                        v-if="selectedSearch.profile_pic !== null"
                        :src="selectedSearch.profile_pic"
                      ></v-img>
                      <v-avatar v-else color="red" size="40">
                        <span class="white--text text-h6">{{
                          getInitials(selectedSearch.name)
                        }}</span>
                      </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>
                        <span class="searchList-title">{{
                          selectedSearch.name
                        }}</span>
                        <v-chip
                          small
                          class="mx-2 mt-n1"
                          :class="selectedSearch.user_type"
                          style="font-size: 17px"
                        >
                          {{ selectedSearch.user_type}}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-subtitle
                        class="searchList-secondryText"
                        v-if="selectedSearch.user_type === 'student'"
                        v-html="
                          `${selectedSearch.grade_teacher} (Grade ${selectedSearch.grade_title})`
                        "
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="searchList-secondryText"
                        v-if="selectedSearch.user_type === 'student'"
                        v-html="selectedSearch.school_name"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        class="searchList-secondryText"
                        v-if="selectedSearch.user_type === 'teacher'"
                        v-html="
                          `Grade ${selectedSearch.grade_title} (${selectedSearch.school_name})`
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </v-col>

                <v-col cols="12">
                  <span class="textField-label"
                    >How much you want to donate?</span
                  >
                  <v-text-field
                    v-model="amount"
                    prepend-inner-icon="mdi-currency-usd"
                    outlined
                    dense
                    type="number"
                    :rules="[rules.required]"
                    v-on:keyup="validate_amount('amount')"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" class="py-0 mt-n7">
                  <span class="textField-label">
                    <v-chip-group
                      v-model="donationSelection"
                      active-class="deep-purple--text text--accent-4"
                    >
                      <v-chip
                        v-for="size in donationSuggestions"
                        :key="size"
                        :value="size"
                        label
                        :class="$vuetify.breakpoint.smAndUp ? 'px-5' : 'px-3'"
                        @click="amount = size"
                      >
                        <span class="donationSuggestion-item">
                          {{ `$${size}` }}
                        </span>
                      </v-chip>
                    </v-chip-group>
                  </span>
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                  v-if="this.campaign.campaign_detail.id != 204"
                >
                  <span class="textField-label">
                    <v-checkbox
                      v-model="checkbox"
                      color="#38A737"
                      :label="`I want to pay the platform fees too (${stripeGatewayPercentage}%)`"
                    ></v-checkbox>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-col cols="12">
            <!--  <span class="textField-label">
              <v-list-item-avatar class="align-self-center">
                <v-img
                  src="@/assets/thingsToDo/pledge.png"
                  height="30"
                  width="20"
                ></v-img> </v-list-item-avatar
              >Pledge</span
            >-->
            <v-divider></v-divider>
            <!--  <v-list-item v-show="selectedSearch.name" style="z-index: 1">
              <span class="textField-label">
                <v-checkbox v-model="pledge" color="#38A737"></v-checkbox>
              </span>
              <v-list-item-title style="margin-left: 20px">
                <span class="searchList-title"
                  >I pledge to donate 20% extra when
                  {{ selectedSearch.name }} completes WizFit video
                  challenge</span
                >
              </v-list-item-title>
            </v-list-item>-->
          </v-col>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>

      <v-card-actions class="pt-5 pb-7 d-flex justify-center">
        <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
        <v-btn
          class="payDonation-btn py-7 pl-9 pr-6"
          color="#38A737"
          :loading="payDonationBtnLoading"
          :disabled="payDonationBtnDisabled"
          :dark="!payDonationBtnDisabled"
          right
          @click="payDonationHandler()"
        >
          <v-icon class="mr-5" size="30" left dark> mdi-lock </v-icon>
          <span>Securely Pay ${{ finalDonationAmount }}</span>
          <v-icon class="ml-7" size="38" right dark> mdi-arrow-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { CAMPAIGN_DETAIL, CAMPAIGN_ID, ID, SEARCH } from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
import {
  API_PUBLIC_MASTER_SEARCH,
  API_PUBLIC_MAKE_DONATION,
  API_PUBLIC_MASTER_GATEWAY_PERCENTAGE,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";

export default {
  name: "MakeDonationModal",
  components: {},
  data() {
    // this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    return {
      rules: RULES,
      //  pledge: false,
      stripeGatewayPercentage: 0,
      sessionId: "",
      publishableKey: "",
      stripeAccount: "",
      searchQuery: "",
      searchList: [],
      selectedSearch: {},
      searchLoading: false,
      donorFirstName: "",
      donorLastName: "",
      donorEmail: "",
      donorPhone: "",
      donorSpecialMsg: "",
      amount: 0,
      donationSelection: -1,
      payDonationBtnLoading: false,
      donationSuggestions: [30, 50, 100, 200],
      checkbox: true,
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      showGetter: "publicDonationPage/getMakeDonationModalShow",
      campaign: "publicDonationPage/getCampaign",
      autoSelect: "publicDonationPage/getMakeDonationAutoSelect",
    }),
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        if (!value) this.clearModal();
        return this.toggleModal({ show: value });
      },
    },
    payDonationBtnDisabled() {
      let flag = true;
      if (!this.selectedSearch.name) flag = false;
      if (!this.amount) flag = false;
      return !flag;
    },
    finalDonationAmount() {
      if (this.checkbox) {
        let amt = 0;
        let a1 = parseInt(this.amount) + 0.4;
        // console.log(this.amount)
        // console.log(this.stripeGatewayPercentage, "fhfjhfdshfdsfhsfjhfhsh");
        let a2 = 1 - this.stripeGatewayPercentage / 100;
        // console.log(a1,a2)
        amt = a1 / a2;
        return amt.toFixed();
      } else return this.amount;
    },
    getCampaignDetail() {
      return this.getCampaign;
    },
  },
  watch: {
    //Watcher for detecting change in SEARCH
    searchQuery: function () {
      this.searchLoading = true;
      this.debounceGetCampaignData();
    },
    show(item) {
      if (item) {
        this.selectedSearch = this.autoSelect ? this.autoSelect : {};
      }
    },
    // checkbox() {
    //   if (this.checkbox) {
    //     let amt = 0;
    //     let a1 = this.amount + 0.4;
    //     let a2 = 1 - this.stripeGatewayPercentage / 100;
    //     amt = a1 / a2;
    //     console.log(amt)
    //   }
    // },
  },
  methods: {
    ...mapActions({
      toggleModal: "publicDonationPage/toggleMakeDonationModal",
      showToast: "snackBar/showToast",
    }),
    openModal() {
      this.getGatewayPercentage();
    },
    clearModal() {
      this.searchQuery = "";
      this.searchList = [];
      this.selectedSearch = {};
      this.searchLoading = false;
      this.donorFirstName = "";
      this.donorLastName = "";
      this.donorEmail = "";
      this.donorSpecialMsg = "";
      this.amount = 0;
      this.donationSelection = -1;
      this.checkbox = true;
      this.payDonationBtnLoading = false;
      this.sessionId = "";
      this.publishableKey = "";
      this.stripeAccount = "";
    },
    payDonationHandler() {
      if (this.$refs.donationForm.validate()) {
        this.payDonationBtnLoading = true;
        let formData = {};
        formData[
          "donor_name"
        ] = `${this.donorFirstName.trim()} ${this.donorLastName.trim()}`;
        formData["donor_email"] = this.donorEmail;
        formData["phone"] = this.donorPhone;
        formData["amount"] = this.amount;
        formData["donor_special_message"] = this.donorSpecialMsg;
        formData["campaign"] = this.campaign[CAMPAIGN_DETAIL][ID];
        formData["payment_mode"] = "Online";
        formData["donation_for"] = this.selectedSearch.user_type;
        formData[this.selectedSearch.user_type] = this.selectedSearch.id;
        formData["user_paid_processing_fees"] = this.checkbox;
        //  formData["pledge"] = this.pledge;
        if (this.campaign.type) {
          let type_id = this.campaign.type.type + "_id";
          formData["cp_name"] = this.campaign.type.type;
          formData["cp_value"] = this.campaign.type[type_id];
        }
        const successHandler = (res) => {
          console.log("Search List: ", res.data);
          // You will be redirected to Stripe's secure checkout page
          this.publishableKey = res.data.stripe_publish_key;
          this.stripeAccount = res.data.account_id;
          this.sessionId = res.data.transcation_id;
          console.log(
            "publishableKey:",
            this.publishableKey,
            "stripeAccount: ",
            this.stripeAccount,
            "this.sessionId: ",
            this.sessionId
          );
          this.setupStrip();
        };
        const failureHandler = (res) => {
          console.log("Failure: ", res);
          this.clearModal();
          this.payDonationBtnLoading = false;
          this.toggleModal({ show: false });
        };
        Axios.request_POST(
          API_PUBLIC_MAKE_DONATION,
          formData,
          {},
          successHandler,
          failureHandler,
          false,
          false
        );
      }
    },
    getCampaignSearchList() {
      const _this = this;
      const successHandler = (res) => {
        _this.searchList = res.data.search_list;
        _this.searchLoading = false;
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
        _this.searchLoading = false;
      };
      let formData = {};
      formData[SEARCH] = this.searchQuery;
      formData[CAMPAIGN_ID] = this.campaign[CAMPAIGN_DETAIL][ID];
      Axios.request_GET(
        API_PUBLIC_MASTER_SEARCH,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    searchItemClickHandler(item) {
      this.selectedSearch = item;
      this.searchQuery = "";
      this.searchList = [];
    },
    debounceGetCampaignData: _.debounce(function () {
      this.getCampaignSearchList();
    }, 500),
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function (e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    async configureStripe() {
      if (this.stripeAccount) {
        this.stripe = await window.Stripe(this.publishableKey, {
          stripeAccount: this.stripeAccount,
        });
      } else {
        this.stripe = await window.Stripe(this.publishableKey);
      }

      await this.stripe.redirectToCheckout({ sessionId: this.sessionId });
    },
    /**
     * function returns user's initials
     * param {string}  user's full name
     **/
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    async setupStrip() {
      await this.includeStripe(
        "js.stripe.com/v3/",
        function () {
          this.configureStripe();
        }.bind(this)
      );
    },
    validate_amount(amount) {
      console.log(amount);
      if (this[amount] - Math.floor(this[amount]) !== 0) {
        console.log(this[amount], typeof this[amount], "amount");
        this[amount] = this[amount].split(".")[0];
      }
    },
    getGatewayPercentage() {
      const successHandler = (res) => {
        console.log(
          "stripe_gateway_percentage: ",
          res.data.stripe_gateway_percentage
        );
        this.stripeGatewayPercentage = res.data.stripe_gateway_percentage;
      };
      const failureHandler = (res) => {
        console.log("Failure: ", res);
      };
      let formData = {};
      Axios.request_GET(
        API_PUBLIC_MASTER_GATEWAY_PERCENTAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        false
      );
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
  mounted() {
    this.getGatewayPercentage();
  },
};
</script>

<style scoped>
.modalTitle {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  line-height: 315%;
  color: #ffffff;
}
.sectionTitle span {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #2c1963;
}
.sectionTitle > .sectionTitleUnderline {
  background: #2c1963;
  border-radius: 45px;
  width: 80%;
  border-width: 1.5px;
}
.textField-label {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 21px;
  line-height: 25px;
  color: #2d2d2d;
}
.payDonation-btn span {
  letter-spacing: 0px;
  text-transform: none;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 161.5%;
  color: #ffffff;
}
.donationSuggestion-item {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #5c5c5c;
}
.searchList-title {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 161.5%;
  color: #1d1d1d;
}
.searchList-secondryText {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 161.5%;
  color: #757575;
}
.student {
  background: rgba(0, 133, 255, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #0085ff !important;
  text-transform: capitalize;
}
.teacher {
  background: rgba(56, 167, 55, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #38a737 !important;
  text-transform: capitalize;
}
.school {
  background: rgba(45, 45, 45, 0.12) !important;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 161.5%;
  color: #2d2d2d !important;
  text-transform: capitalize;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .modalTitle {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 219%;
    color: #ffffff;
  }
  .sectionTitle span {
    font-family: Roboto Slab;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    color: #2c1963;
  }
  .textField-label {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 25px;
    color: #2d2d2d;
  }
  .payDonation-btn span {
    letter-spacing: 0px;
    text-transform: none;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 161.5%;
    color: #ffffff;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
}
</style>
